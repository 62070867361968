export const NAV_HEADER_MENU = {
    EDITOR: 'editor',
    ARTICLES: 'articles'
};

export const CHECKING_TITLE_STATUS = {
    IDLE: 0,
    CHECKING: 1,
    INVALID: 2,
    CORRECT: 3
};

export const AUTOSAVE_STATUS = {
    IDLE: '',
    SAVING_CHANGES: 'Saving changes...',
    CHANGES_NOT_SAVED: 'Changes not saved!',
    CHANGES_SAVED: 'Changes saved.'
};

export const MAX_ARTICLE_BODY_PIC_SIZE = 2000000;

export const languagesList = [
    { name: 'CSS', value: 'css' },
    { name: 'HTML/XML', value: 'html' },
    { name: 'PHP', value: 'php' },
    { name: 'Java', value: 'java' },
    { name: 'JavaScript', value: 'javascript' },
    { name: 'SQL', value: 'sql' },
    { name: 'Python', value: 'python' },
    { name: 'Ruby', value: 'ruby' },
    { name: 'C/C++', value: 'c' },
    { name: 'C#', value: 'csharp' },
    { name: 'Perl', value: 'perl' },
    { name: 'TypeScript', value: 'typescript' },
    { name: 'SQL', value: 'sql' },
    { name: 'Scala', value: 'scala' },
    { name: 'Bash (Shell)', value: 'bash' },
    { name: 'Plain (Text)', value: '' },
    { name: 'JSON', value: 'json' },
    { name: 'R', value: 'r' }
].sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? (a.value > b.value ? 1 : -1) : -1));
