import { initStore } from './Store';
import { NAV_HEADER_MENU } from '../Constants/values';

const configureStore = () => {
    const actions = {
        /**
         * Set profile_complete to true/false
         * @param {String} token Auth token of user
         * @param {Object} user_id User id
         */
        changeProfileCompletion: (state, token, user_id, user_profile_id) => {
            localStorage.setItem('token', token);
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('user_profile_id', user_profile_id);
            return { user_id: user_id, user_token: token, user_profile_id: user_profile_id, is_user_logged_in: true };
        },
        logoutUser: state => {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_profile_id');
            return { is_user_logged_in: false, user_id: null, user_token: null, user: null, user_profile_id: null };
        },
        setUserDetail: (state, user) => ({ user: user }),
        setNavSelectedMenu: (state, menu_key) => ({ nav_selected_menu: menu_key }),
        setFetchedUser: (state, value) => ({ fetched_user: value })
    };

    initStore(actions, {
        is_user_logged_in: !!localStorage.getItem('token'),
        user_token: localStorage.getItem('token'),
        user_id: localStorage.getItem('user_id'),
        user_profile_id: localStorage.getItem('user_profile_id'),
        user: null,
        nav_selected_menu: NAV_HEADER_MENU.EDITOR,
        fetched_user: false
    });
};

export default configureStore;
