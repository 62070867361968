import { initStore } from './Store';

const configureStore = () => {
    const actions = {
        /**
         * Set profile_complete to true/false
         * @param {String} token Auth token of user
         * @param {Object} user_id User id
         */
        changeCurrentArticleTitle: (state, title) => ({ currentArticleTitle: title }),
        changeCurrentArticleBody: (state, body) => ({ currentArticleBody: body }),
        changeCurrentArticleShortDescription: (state, shortDescription) => ({
            currentArticleShortDescription: shortDescription
        }),
        changeCurrentArticlePic: (state, pic) => ({ currentArticlePic: pic }),
        changeCurrentArticleId: (state, id) => ({ currentArticleId: id }),
        changeCurrentArticleAuthorId: (state, authorId) => ({ currentArticleAuthorId: authorId })
    };
    initStore(actions, {
        currentArticleTitle: '',
        currentArticleBody: '',
        currentArticleShortDescription: '',
        currentArticlePic: '',
        currentArticleId: null,
        currentArticleAuthorId: null
    });
};

export default configureStore;
