import React, { lazy, Suspense } from 'react';
import { useStore } from './Stores/Store';
import { Spin } from 'antd';

const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const MainPage = lazy(() => import('./MainPage/MainPage'));
const Header = lazy(() => import('./Header/HeaderContent'));

const App = props => {
    const [AppStore, dispatch] = useStore();

    const renderComponent = () => {
        if (AppStore.is_user_logged_in) {
            return (
                <>
                    <Header />
                    <MainPage />
                </>
            );
        } else {
            return <LoginPage />;
        }
    };

    return (
        <div className='App'>
            <Suspense fallback={<Spin size='large' />}>{renderComponent()}</Suspense>
        </div>
    );
};

export default App;
